<template>
  <OrganismCompaniesGrid />
</template>

<script>
import OrganismCompaniesGrid from '@/components/OrganismCompaniesGrid'

export default {
  title: vm => vm.$t('pages.companies.title'),

  components: {
    OrganismCompaniesGrid,
  },
}
</script>

<template>
  <MoleculeGrid
    :title="$t('company.many.title')"
    :loading="loading"
    :columns="columns"
    :rows="companies"
    :search="['name', 'phone', 'inn']"
    :pagination="true"
    :empty-message="$t('company.error.empty')"
    :outline="false"
  >
    <template slot="actions">
      <MoleculeModal
        :title="modal.title"
        :show="modal.show"
        @close="modal.show = false"
      >
        <template slot="body">
          <MoleculeForm
            :validator="$v"
            :loading="submitLoading"
            :disabled="submitLoading"
          >
            <div class="row">
              <div class="col-lg-12">
                <AtomInput
                  class="mb-3"
                  v-model.trim="company.name"
                  label="company.name"
                  :disabled="true"
                />
              </div>
              <div class="col-lg-12">
                <AtomInput
                  class="mb-3"
                  v-model.trim="company.phone"
                  label="phone"
                  type="phone"
                />
              </div>
              <div class="col-lg-12">
                <AtomInput
                  v-model.trim="company.website"
                  label="website"
                  class="mb-3"
                  beforeInput="https://"
                />
              </div>
            </div>
          </MoleculeForm>
        </template>
        <template slot="footer">
          <AtomButton
            class="me-auto"
            @click="modal.show = false"
            :disabled="submitLoading"
          >
            {{ $t('action.cancel') }}
          </AtomButton>
          <AtomButton
            color="primary"
            class="ms-auto"
            :loading="submitLoading"
            :disabled="!dirty || $v.$anyError || submitLoading"
            @click="submitHandler"
          >
            {{ modal.submit }}
          </AtomButton>
        </template>
      </MoleculeModal>
    </template>
    <template #phone="{ data }"><AtomPhone>{{ data.phone }}</AtomPhone></template>
    <template #action="{ data }">
      <div class="text-end">
        <AtomButton
          variant="ghost"
          color="primary"
          :disabled="submitLoading"
          @click.prevent="editCompanyHandler(data)"
        >
          <edit-icon />
        </AtomButton>
        <!-- <AtomButton
          variant="ghost"
          color="danger"
          class="ms-2"
          :disabled="submitLoading"
          @click.prevent="removeCompanyHandler(data)"
        >
          <trash-icon />
        </AtomButton> -->
      </div>
    </template>
  </MoleculeGrid>
</template>

<script>
import { isEqual } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import { url } from '@/validators'

import MoleculeGrid from '@/components/MoleculeGrid'
import MoleculeModal from '@/components/MoleculeModal'
import MoleculeForm from '@/components/MoleculeForm'
import AtomInput from '@/components/AtomInput'
import AtomButton from '@/components/AtomButton'
import AtomPhone from '@/components/AtomPhone'

export default {
  name: 'OrganismCompaniesGrid',

  components: {
    MoleculeGrid,
    MoleculeModal,
    MoleculeForm,
    AtomInput,
    AtomButton,
    AtomPhone,
  },

  data: () => ({
    company: {
      id: '',
      name: '',
      website: '',
      phone: '',
    },

    modal: {
      title: '',
      show: false,
      submit: '',
    },

    loading: true,
    submitLoading: false,
    dirty: false,
  }),

  validations: {
    company: {
      phone: { required, minLength: minLength(18) },
      website: { required, url },
    },
  },

  computed: {
    ...mapGetters(['companies']),

    columns () {
      return [
        { key: 'idx', name: '№', width: 7 },
        { key: 'id', name: this.$t('inn.label'), width: 15 },
        { key: 'name', name: this.$t('company.name.label'), width: 40 },
        { key: 'phone', name: this.$t('phone.label'), width: 20 },
        { key: 'website', name: this.$t('website.label'), width: 15 },
        { key: 'action', width: 10 },
      ]
    },
  },

  watch: {
    company: {
      handler () {
        this.dirty = !isEqual(this.$options.originalCompany, this.company)
      },
      deep: true,
    },
  },

  async mounted () {
    try {
      await this.fetchCompanies()
    } catch {} finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions(['editCompany', 'fetchCompanies']),

    async submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        this.submitLoading = true
        await this.editCompany(this.company)
        this.modal.show = false
        this.prepareData()
        this.$notify({
          title: this.$t('action.success'),
          text: this.$t('company.success.edit'),
          type: 'success',
        })
      } catch {} finally {
        this.submitLoading = false
      }
    },

    editCompanyHandler (company) {
      this.modal.title = this.$t('company.edit')
      this.modal.submit = this.$t('action.edit')
      this.prepareData(company)
      this.modal.show = true
    },

    // removeCompanyHandler (company) {
    //   this.submitLoading = true
    //   try {
    //     this.$confirm({
    //       title: `${this.$t('action.warning')}!`,
    //       message: this.$t('company.action.remove', { name: company.name }),
    //       status: 'danger',
    //       buttons: [
    //         this.$t('action.cancel'),
    //         {
    //           text: this.$t('action.remove'),
    //           confirm: true,
    //         },
    //       ],
    //       callback: async confirm => {
    //         if (confirm) {
    //           try {
    //             await this.removeCompany(company.id)
    //           } catch {}
    //         }
    //       },
    //     })
    //   } catch {} finally {
    //     this.submitLoading = false
    //   }
    // },

    prepareData (data) {
      this.company = {
        id: data?.id || '',
        name: data?.name || '',
        website: data?.website || '',
        phone: data?.phone || '',
      }
      this.$options.originalCompany = { ...this.company }
    },
  },
}
</script>
